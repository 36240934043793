<div class="training-development-section">
    <!-- Training Session Ticker -->
    <div class="training-ticker">
        <div class="ticker">
            <img class="ticker-icon" src="../../../../assets/images/speaker_icon.webp" />
            <p>You can now get <b>FREE</b> Training Need Assessments Coaching for your team</p>
            <button (click)="goToTraining()">Learn More</button>
        </div>
    </div>
    <!-- Training banner -->
    <div class="banners">
        <img class="img-banner" src="../assets/images/training-banner.webp">
    </div>
    <!-- About the Training and Development Section -->
    <div class="about-training">
        <h4>About Worknigeria Training</h4>
        <p class="about-text">
            We understand that investing in employee development can be time-consuming and
            expensive, but at Worknigeria, we have designed our programs to be cost-effective
            and time-efficient. Our team of experienced Training and Development specialists work with you to identify
            your training needs, design a customized program, and measure its effectiveness. Our
            training emphasizes the development of knowledge, skills, and attitudes in diverse areas;
            all with the intent of improving your individual staff competence, team performance,
            overall productivity and business profitability.
        </p>
        <div class="grid-container">
            <div class="grid-item left-side">
                <p>
                    Global business dynamics drive the constantly changing and evolving environment in which we all
                    do business. Our training and development programs offer opportunities for individuals or companies
                    to proactively upskill and gain better understanding across various topics to stay ahead of the
                    competition. We provide a number of open courses in addition to completely customizable training
                    for your organization.
                </p>
                <button class="btn-download" (click)="downloadBrochure()">
                    <img src="../../../../assets/images/download-brochure-icon.svg" />
                    Download our 2025 training brochure
                </button>
            </div>
            <div class="grid-item right-side">
                <p>
                    Do you have a special training requirement for yourself or your team?
                </p>
                <button class="btn-request" (click)="sendARequest()">
                    Send us a request
                </button>
            </div>
        </div>
    </div>
    <!-- Training solutions Section -->
    <div class="training-section">
        <h3>Worknigeria Training Solution</h3>
        <div class="row">
            <div class="training-card-container">
                <div class="card training-card" [ngClass]="onReadMore ? 'training-card-height' : 'training-card'"
                    *ngFor="let card of training_solution_cards">
                    <img [src]="card.imgSrc" alt="{{ card.heading }}" />
                    <h2>{{ card.heading }}</h2>
                    <p class="read-more" (click)="readMore()">Read more <span *ngIf="onReadMore">^^</span> <span
                            *ngIf="!onReadMore">>></span> </p>
                    <p class="p-text" *ngIf="onReadMore">{{ card.text }}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Upcoming Training Section -->
    <div class="upcoming-training" *ngIf="upcoming_trainings.length > 0" id="upcoming-courses">
        <div class="training-search">
            <div class="search-container">
                <input type="text" class="search-bar" placeholder="Search Course" [(ngModel)]="searchTerm"/>
                <button (click)="applyFilters()" class="search-button">Search</button>
            </div>
            <div class="custom-dropdown">
                <select [(ngModel)]="selectedMonth" (change)="applyFilters()">
                    <option value="" selected>Filter By Month</option>
                    <option *ngFor="let month of months" [value]="month.value"> {{ month.name }}</option>
                </select>
                <img src="../../../../assets/images/arrow-down.webp" alt="dropdown icon" class="dropdown-icon" />
            </div>
        </div>
        <h2>Our Upcoming Trainings</h2>
        <div class="row">
            <div class="card-container">
                <div class="card upcoming-cards" *ngFor="let card of paginatedCards">
                    <img [src]="card.image_link" alt="{{ card.title }}" />
                    <h3>{{ card.title }}</h3>
                    <p><span>Date: </span>{{ correctDate(card.timestamp) }} - {{ correctDate(card.end_date) }}</p>
                    <p><span>Duration: </span>{{ card.duration }}</p>
                    <p><span>Virtual Class: </span>NGN{{ card.virtual_price }}</p>
                    <p><span>Physical Class: </span>NGN{{ card.physical_price }}</p>
                    <button (click)="openLink(card.training_link)">Enroll</button>
                </div>
            </div>
            <ngb-pagination [collectionSize]="filteredCards.length" [(page)]="currentPage" [pageSize]="pageSize"
                (pageChange)="onPageChange($event)" [maxSize]="5" [ellipses]="false" [rotate]="true">
            </ngb-pagination>
        </div>
    </div>
    <!-- Free Consultation Banners -->
    <div class="free-consult">
        <h2>Free Consultations</h2>
        <div class="green-bg row">
            <div class="col-lg-4 col-12">
                <img src="../../../../assets/images/consult-icon.webp" />
            </div>
            <div class="col-lg-1 p-0 m-0 col-0"></div>
            <div class="col-lg-7 col-12 consult-text">
                <h3>Free Training Need Assessment Consultation</h3>
                <p>"This 30-minute Training Needs Assessment Coaching Session is available free of charge to HR
                    professionals and Business Owners. When you book an appointment, you get a private opportunity
                    to learn how to assess your workforce training needs and ask questions about organizational
                    learning and development practices."</p>
                <a href="https://bit.ly/training-wn" target="_blank">Book Appointment</a>
            </div>
        </div>
    </div>
    <!-- Faculty Section & Key Area Development -->
    <div class="faculty-section">
        <h3>Worknigeria Faculty</h3>
        <img src="../../../../assets/images/key-development.png" class="mobile-view-img">
        <p>Our faculty stands unparalleled in expertise and dedication, embodying the pinnacle of professional
            excellence. Each member brings a wealth of knowledge and real-world experience, ensuring that our curriculum
            is current and deeply enriching. With a passion for teaching and a commitment to fostering a dynamic
            learning environment, our faculty goes above and beyond to support and inspire our training participants.
        </p>
        <div class="key-development">
            <h3>Key Development Area</h3>
            <div class="row">
                <div class="col-lg-6">
                    <ul class="custom-list">
                        <li *ngFor="let item of items">{{ item }}</li>
                    </ul>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../assets/images/key-development.png">
                </div>
            </div>
        </div>
    </div>
    <!-- Testimonials Section -->
    <div class="testimonials-section">
        <h4>Testimonials</h4>
        <!-- Carousel Cards -->
        <div id="cardCarousel" class="carousel slide" data-bs-interval="false">
            <!-- Carousel inner -->
            <div class="carousel-inner">
                <div *ngFor="let cardSet of groupedCards(); let i = index" class="carousel-item"
                    [ngClass]="{'active': i === 0}">
                    <div class="row justify-content-center">
                        <div class="col" *ngFor="let card of cardSet; let j = index">
                            <div class="card testimonials-card">
                                <p class="name-initials" [ngClass]="getColorClass(j)">{{ getInitials(card.companyName)
                                    }}</p>
                                <h5 class="card-title">{{card.quote}}</h5>
                                <div class="star-icons">
                                    <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                                        <i class="fas fa-solid fa-star"
                                            [ngClass]="{ 'golden-star': star <= card.stars, 'white-star': star > card.stars }"></i>
                                    </ng-container>
                                </div>
                                <p class="card-text">– {{card.companyName}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Indicators -->
            <div class="carousel-indicators">
                <button *ngFor="let page of [].constructor(totalPages); let i = index" type="button"
                    data-bs-target="#cardCarousel" [attr.data-bs-slide-to]="i" [ngClass]="{ 'active': i === 0 }"
                    [attr.aria-current]="i === 0 ? 'true' : null" [attr.aria-label]="'Slide ' + (i + 1)"></button>
            </div>
        </div>
        <!-- Carousel Cards -->
    </div>
</div>