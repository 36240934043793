<section class="bg-grey">
  <section class="addjob-sec common-padd-small common-padd-lt-rt">
    <div class="container-fluid">
      <h1 class="top-page-ttl">Add Job</h1>
      <div class="mystatistics-info">
        <!-- <a class="green-btn" style="background-color: grey;pointer-events: none;"><img
                    src="../../../../assets/images/add_circle_outline_24px.png"> Add job</a> -->
        <p *ngIf="this.countData != ''">
          <b>My statistics:</b> Active Jobs:
          <span>{{ this.countData.activeJob }}</span> | Total views:
          <span>{{ this.countData.viewJob }}</span> | Total applications:
          <span>{{ this.countData.appliedJob }}</span>
        </p>
      </div>
    </div>
  </section>

  <section class="bodyjobsearch-area common-padd-small common-padd-lt-rt pt-0">
    <div class="container-fluid">
      <div class="row flex-sm-row-reverse">
        <div class="col-lg-12">
          <div class="title-wrap">
            <h3 class="hdttl">Add Job</h3>
            <div class="btn-set select-wrap superadmin-flex">
              <!-- *ngIf=" this.userDeatils.userType == 'super_admin'" -->
              <form [formGroup]="userManagement" *ngIf="this.userDeatils.userType == 'super_admin'">
                <div class="select-row">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label style="color: black"><i _ngcontent-uel-c354="" class="fas fa-search"></i>
                      Companies</mat-label>
                    <input type="text" aria-label="Number" matInput formControlName="companies"
                      [matAutocomplete]="auto" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                      (optionSelected)="changeCompany($event.option.value)">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.companyName">
                        {{ option.companyName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </form>
              <a class="btn bdr-green" href="javascript:void(0);" (click)="cancelJob()">CANCEL</a>
              <a class="btn btn-green" href="javascript:void(0);" (click)="addJob()">SAVE</a>
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div class="addjob-info">
            <ng-container>
              <form class="fillupjob example-form custom-height-adjust" [formGroup]="postJob">
                <div *ngIf="this.userDeatils.userType == 'super_admin'" class="profilelogo">
                  <img [src]="
                        companyPic
                          ? companyPic
                          : '../../../assets/images/sm-Logo.png'
                      " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                </div>
                <div *ngIf="this.userDeatils.userType != 'super_admin'" class="profilelogo">
                  <img [src]="
                        this.companyData.companyLogo
                          ? this.companyData.companyLogo
                          : '../../../assets/images/sm-Logo.png'
                      " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                  <!-- <a href="javascript:void(0);" class="green-btn">Upload logo</a> -->
                </div>
                <div class="jt form-group">
                  <!-- <input type="text" placeholder="Job title (required)" class="form-control"
                                      formControlName="jobTitle"> -->
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Job title</mat-label>
                    <input type="text" matInput placeholder="Job title (required)" required="required"
                      formControlName="jobTitle" />
                  </mat-form-field>
                </div>
  
                <div *ngIf="f.jobTitle.touched && f.jobTitle.invalid">
                  <span class="text-left text-danger pb-1" *ngIf="f.jobTitle.errors.required">Job Title is required
                  </span>
                </div>
  
                <div class="jd form-group">
                  <h4>Job description (required)</h4>
                  <p>
                    Add Job Description, Responsibilities, Skills, Education,
                    Experience, Remunerations, Other Requirements, how to apply,
                    and other necessary information.
                    <b>You can copy and paste into this space. Please use 'Ctrl
                      C' to copy and use 'Ctrl V' to paste.</b>
                  </p>
                  <!-- <div class="editor-text"><img src="../../../assets/images/Richtextedit.png"></div> -->
                  <angular-editor [(ngModel)]="htmlContent" [config]="editorConfig"
                    formControlName="description"></angular-editor>
                  <div *ngIf="f.description.touched && f.description.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.description.errors.required">Description is required
                    </span>
                  </div>
                </div>
  
                <div class="form-group">
                  <!-- <input type="text" placeholder="Company name" class="form-control"
                                      formControlName='campanyName' readonly> -->
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Company name</mat-label>
                    <input type="text" matInput placeholder="Company name" required="required"
                      formControlName="campanyName" readonly />
                  </mat-form-field>
                </div>
                <div *ngIf="f.campanyName.touched && f.campanyName.invalid">
                  <span class="text-left text-danger pb-1" *ngIf="f.campanyName.errors.required">Company Name is required
                  </span>
                </div>
                <div class="form-group">
                  <!-- <input type="text" placeholder="Company name" class="form-control"
                                      formControlName='campanyName' readonly> -->
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Behalf of company</mat-label>
                    <input type="text" matInput placeholder="Company name" formControlName="company_behalf_name" />
                  </mat-form-field>
                </div>
  
                <div class="form-group">
                  <div id="file-upload-filename">
                    <div class="profilelogo">
                      <img [src]="
                            company_behalf_logo
                              ? company_behalf_logo
                              : '../../../assets/images/sm-Logo.png'
                          " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                      <!-- <a href="javascript:void(0);" class="green-btn">Upload logo</a> -->
                    </div>
                  </div>
  
                  <input type="file" style="display: none" #UploadFileInput id="fileUpload"
                    (change)="fileChangeEvent($event)" name="fileUpload" multiple="multiple" accept="image/*" />
                  <a class="btn btn-green" style="margin-left: 0;" href="javascript:void(0);" (click)="UploadFileInput.click()">Upload logo for
                    behalf of company</a>
                  <div *ngIf="logoUploadBehalfOfCompanyError">
                    <span class="text-left text-danger pb-1">Logo upload failed
                    </span>
                  </div>
                </div>
  
                <div class="radio-nz-wrap">
                  <div class="form-group">
                    <mat-radio-group aria-label="Select an option" formControlName="location_type">
                      <mat-radio-button value="NIGERIA" (change)="locationDropEvent($event)">
                        NIGERIA </mat-radio-button><br />
                      <mat-radio-button value="OUT_OF_COUNTRY" (change)="locationDropEvent($event)">
                        OUT OF COUNTRY</mat-radio-button>
                      <br />
                      <mat-radio-button value="REMOTE" (change)="locationDropEvent($event)">REMOTE
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="f.location_type.touched && f.location_type.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.location_type.errors.required">Location Type is
                      required
                    </span>
                  </div>
  
                  <div class="form-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>States</mat-label>
                      <!-- <mat-select formControlName="location"
                                              (selectionChange)="joblocationName($event.value)">
                                              <mat-option value="{{row?.id}}"
                                                  *ngFor="let row of cityRecords;let i = index">
                                                  {{row?.city}} ,{{row?.state}}</mat-option>
                                          </mat-select> -->
                      <input type="text" aria-label="City" matInput formControlName="location" [matAutocomplete]="auto" />
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="joblocationName($event.option.value)">
                        <mat-option *ngFor="let option of filteredLocation | async" [value]="option.state">
                          {{ option.state }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="f.location.touched && f.location.invalid">
                  <span class="text-left text-danger pb-1" *ngIf="f.location.errors.required">Location is required
                  </span>
                </div>
                <div class="radio-nz-wrap">
                  <div class="form-group">
                    <p style="margin-bottom: 0;">Is this a 3MTT job?</p>
                    <div class="checkbox-div">
                      <mat-radio-group aria-label="Select an option" formControlName="is_3mtt" class="radio-group">
                        <mat-radio-button style="margin-bottom: 0;" [value]="false">
                          NO </mat-radio-button>
                        <mat-radio-button style="margin-bottom: 0;" [value]="true">
                          YES</mat-radio-button>
                      </mat-radio-group>
                      <p class="mtt-text">
                        If you select YES this job will be tagged as a 3MTT job and only members of 3MTT will be able to apply for the job. <a class="mtt-link" href="https://3mtt.nitda.gov.ng/">Click Here</a> to learn more about 3MTT
                      </p>
                    </div>
                  </div>
                </div>
  
                <div class="form-group">
                  <!-- <input type="text" placeholder="Company name" class="form-control"
                                      formControlName='campanyName' readonly> -->
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>City name</mat-label>
                    <input type="text" matInput placeholder="City name" formControlName="city" />
                  </mat-form-field>
                </div>
  
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Employment Type</mat-label>
                    <mat-select formControlName="employementTypes" [(ngModel)]="defaultemployemntTypeName"
                      (selectionChange)="employmentTypeChange($event.value)">
                      <mat-option value="{{ emplomentType?.employmentType }}" *ngFor="
                            let emplomentType of employemntTypeList;
                            let i = index
                          ">
                        {{ emplomentType?.employmentType }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="
                      f.employementTypes.touched && f.employementTypes.invalid
                    ">
                  <span class="text-left text-danger pb-1" *ngIf="f.employementTypes.errors.required">Employment Type is
                    required
                  </span>
                </div>
  
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Job Category</mat-label>
                    <!-- <mat-select formControlName="jobCategoryTypes"
                                              (selectionChange)="jobCategoryChange($event.value)">
                                              <mat-option value="{{jobCategoryType?.id}}"
                                                  *ngFor="let jobCategoryType of filteredCategory;let i = index">
                                                  {{jobCategoryType?.categoryName}}</mat-option>
                                          </mat-select> -->
                    <input type="text" aria-label="Category" matInput formControlName="jobCategoryTypes"
                      [matAutocomplete]="auto2" />
                    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete"
                      (optionSelected)="jobCategoryChange($event.option.value)">
                      <mat-option *ngFor="let option of filteredCategory | async" [value]="option.categoryName">
                        {{ option.categoryName }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div *ngIf="
                      f.jobCategoryTypes.touched && f.jobCategoryTypes.invalid
                    ">
                  <span class="text-left text-danger pb-1" *ngIf="f.jobCategoryTypes.errors.required">Job Category is
                    required
                  </span>
                </div>
                <div class="form-group selectbx">
                  <div class="rightgap">
                    <!-- <select class="form-control" formControlName="minexprience"
                                          (change)="rangeValidation()">
                                          <option value="">Minimum experience</option>
                                          <option value="{{min}}" *ngFor="let min of minExp">{{min}}</option>
                                      </select> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Minimum experience</mat-label>
                      <mat-select formControlName="minexprience" (selectionChange)="rangeValidation()">
                        <mat-option value="{{ min }}" *ngFor="let min of minExp">{{ min }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div *ngIf="f.minexprience.touched && f.minexprience.invalid">
                      <span class="text-left text-danger pb-1" *ngIf="f.minexprience.errors.required">Minimum Exprience is
                        required
                      </span>
                    </div>
                  </div>
                  <div>
                    <!-- <select class="form-control" formControlName="maxexprience">
                                          <option value="">Maximum experience</option>
                                          <option value="{{max}}" *ngFor="let max of maxExp">{{max}}</option>
                                      </select> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Maximum experience</mat-label>
                      <mat-select formControlName="maxexprience" (selectionChange)="rangeValidation()">
                        <mat-option value="{{ max }}" *ngFor="let max of maxExp">{{ max }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <div *ngIf="(f.maxexprience.touched && f.maxexprience.invalid)">
                                              <span class="text-left text-danger pb-1"
                                                  *ngIf="(f.maxexprience.errors.required)">Maximum Exprience is required
                                              </span>
                                          </div> -->
                  </div>
                </div>
  
                <div class="form-group selectbx">
                  <div class="rightgap">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Currency</mat-label>
                      <mat-select formControlName="currency">
                        <mat-option value="{{ currency?.code }}" *ngFor="let currency of currencies">{{ currency?.code }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div *ngIf="f.currency.touched && f.currency.invalid">
                      <span class="text-left text-danger pb-1" *ngIf="f.currency.errors.required">Currency is required
                      </span>
                    </div>

                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Salary</mat-label>
                      <mat-select formControlName="salarySelected" required (selectionChange)="onSalarySelect($event.value)">
                        <mat-option value="range">Range - Minimum and Maximum</mat-option>
                        <mat-option value="Negotiable">Negotiable</mat-option>
                        <mat-option value="Competitive">Competitive</mat-option>
                        <mat-option value="Attractive">Attractive</mat-option>
                      </mat-select>
                    </mat-form-field>                    
  
                    <!-- Maximum and Minimum Salary Section -->
                    <mat-form-field class="example-full-width" appearance="fill" *ngIf="rangeSelected">
                      <mat-label>Minimum Salary</mat-label>
                      <input type="text" matInput placeholder="Minimum Salary" mask="separator.2" thousandSeparator=","
                        required="required" formControlName="minimumSalary" />
                    </mat-form-field>
  
                    <div *ngIf="f.minimumSalary.touched && f.minimumSalary.invalid">
                      <span class="text-left text-danger pb-1" *ngIf="f.minimumSalary.errors.required">Minimum Salary is
                        required
                      </span>
                    </div>
                  </div>
                  <div>
                    
                    <mat-form-field class="example-full-width" appearance="fill" *ngIf="rangeSelected">
                      <mat-label>Maximum Salary</mat-label>
                      <input type="text" matInput placeholder="Maximum Salary" mask="separator.2" thousandSeparator=","
                        formControlName="maximumSalary" />
                    </mat-form-field>                                     
                  </div>
                </div>
                <!-- ---------- -->
  
                <div class="jq form-group">
                  <h4>Qualification required (required)</h4>
                  <div style="margin-top: 10px" class="list-type">
                    <angular-editor [(ngModel)]="htmlContent2" [config]="editorConfig"
                      formControlName="about"></angular-editor>
                    <div *ngIf="f.about.touched && f.about.invalid">
                      <span class="text-left text-danger pb-1" *ngIf="f.about.errors.required">Qualification is required
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group radiofill">
                                  <p>Where will jobseekers apply for this job? (required)</p>
                                  <label><input type="radio" name="apply" (click)="applyJobs('apply on work nigeria')">
                                      Apply on WorkNigeria</label>
                                  <label><input type="radio" name="apply" (click)="applyJobs('apply at external site')">
                                      Apply at external site</label>
                                  <label><input type="radio" name="apply" (click)="applyJobs('apply via email')">
                                      Apply
                                      via email</label>
                                  <div *ngIf="!applyValue">
                                      <span class="text-left text-danger pb-1">Choose an option
                                      </span>
                                  </div>
                              </div> -->
                <div class="ja form-group radiofill">
                  <mat-radio-group aria-label="Select an option">
                    <p>Where will jobseekers apply for this job? (required)</p>
                    <mat-radio-button value="apply on work nigeria" (change)="applyJobs($event, 1)">
                      Apply on Worknigeria </mat-radio-button><br />
                    <mat-radio-button value="apply on external url" (change)="applyJobs($event, 2)">
                      Apply at external site</mat-radio-button>
                    <br />
                    <!-- via link -->
                    <div *ngIf="apply_at_external_site" class="form-group">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Enter the web address where people can apply this job
                        </mat-label>
                        <input type="text" matInput placeholder="Web Link " required="required"
                          formControlName="external_url" />
                      </mat-form-field>
                    </div>
  
                    <div *ngIf="
                          f.external_url.touched &&
                          f.external_url.invalid &&
                          apply_at_external_site
                        ">
                      <span class="text-left text-danger pb-1" *ngIf="f.external_url.errors.required">Web Link is required
                      </span>
                    </div>
                    <mat-radio-button value="apply via email" (change)="applyJobs($event, 3)">via email
                    </mat-radio-button>
                    <!-- via email -->
                    <div *ngIf="apply_via_email" class="form-group">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Enter email address to receive applications and CVs
                        </mat-label>
                        <input type="text" matInput placeholder="Email Address " formControlName="via_email" />
                      </mat-form-field>
                    </div>
                    <!-- 
                      <div
                        *ngIf="
                          f.via_email.touched &&
                          f.via_email.invalid &&
                          apply_via_email
                        "
                      >
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.via_email.errors.required"
                          >Email Address is required
                        </span>
                      </div> -->
                  </mat-radio-group>
                  <div *ngIf="!applyValue">
                    <span class="text-left text-danger pb-1">Choose an option
                    </span>
                  </div>
                </div>
                <!-- <div class="form-group">
  
  
  
                                      <label class="chk">feature job
                                          <input type="checkbox" formControlName="featureJob">
                                          <span class="checkmark"></span>
                                      </label>
  
                                  </div> -->
  
                <div class="btnwrap text-right">
                  <a class="btn btn-green" href="javascript:void(0);" (click)="cancelJob()">CANCEL</a>
                  <a class="js btn btn-green" href="javascript:void(0);" (click)="addJob()">SAVE</a>
                </div>
              </form>
            </ng-container>
          </div>
        </div>


        <!-- <div class="col-lg-3">
                <div class="job-stats">
                    <div class="job-statbox">
                        <div class="job-percent">54%</div>
                        <div class="info">
                            <p>Post quality</p>
                            <h2>Better</h2>
                        </div>
                    </div>

                    <h3>RECOMMENDATIONS</h3>

                    <ul>
                        <li><img src="../../../assets/images/green-arw-up.svg">Your description is looking good! Try
                            adding more content for event more
                            points</li>
                        <li><img src="../../../assets/images/green-arw-up.svg">Try Adding Some Bullet Points</li>
                        <li><img src="../../../assets/images/green-arw-up.svg">Try Adding Some Paragraphs</li>
                    </ul>

                </div>
            </div> -->
      </div>
    </div>
  </section>
</section>