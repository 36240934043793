<section class="bodyjobsearch-area bgcolor">
  <div class="container-fluid max-width">
    <div class="bodyrow">
      <div class="bodyjobsearch-right">
        <div class="title-wrap">
          <h2>Product Manager, Google Cloud</h2>
          <div class="bodyjobsearch-share">
            <ul>
              <li>
                <a href="#"><i class="fas fa-share-alt"></i> SHARE</a>
              </li>
              <li>
                <a href="#"><i class="far fa-heart"></i> SAVED</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="profilelogo">
          <img src="../../../assets/images/sm-Logo.png" />
          <h4>Google <span>New York, NY</span></h4>
        </div>
        <hr />
        <a href="#" class="green-btn">Apply on Worknigeria</a>
        <hr />
        <div class="date">Full-time | 9 days ago | ₦250 - ₦300</div>
        <hr />
        <p>
          At Google, we put our users first. The world is always changing so we
          need Product Managers who are continuously adapting and excited to
          work on products that affect millions of people every day.
        </p>

        <p>
          In this role, you will work cross-functionally to guide products from
          conception to launch by connecting the technical and business worlds.
          You can break down complex problems into steps that drive product
          development at Google speed.
        </p>

        <p>
          One of the many reasons Google consistently brings innovative,
          world-changing products to market is because of the collaborative work
          we do in Product Management. Our team works closely with creative and
          prolific engineers, designers, marketers, etc. to help design and
          develop technologies that improve access to the world's information.
          We're responsible for guiding products throughout the execution cycle,
          focusing specifically on analyzing, positioning, packaging, promoting,
          and tailoring our solutions to our users.
        </p>
        <hr />
        <div class="green-text">
          <i class="fas fa-exclamation-triangle"></i> REPORT THIS LISTING
        </div>
        <hr />
        <h3>Google</h3>
        <p>
          Google aims to build products that organize the world's information
          and make it universally accessible to our users.
        </p>
        <div class="green-text">Google.com</div>
        <div class="green-text">More jobs at Google</div>
      </div>
    </div>
  </div>
</section>
