<div class="download-container">
    <div class="close-icon" (click)="onNoClick()">
        <span class="close"><a>&#10006;</a></span>
    </div>
    <p class="ty-text" *ngIf="this.data['data'] === 'training'">
        Thank you for requesting for our Training Brochure. Kindly provide the information below to continue
    </p>
    <p class="ty-text" *ngIf="this.data['data'] === 'about-us'">
        Thank you for requesting for our Brochure. Kindly provide the information below to continue
    </p>
    <form [formGroup]="userDetailsForm" class="user-form">
        <!-- Input Field For Full Name -->
        <div class="input-container">
            <span class="icon"><img src="../../../../assets/images/name-icon.svg" /></span>
            <input type="text" placeholder="Full Name" class="form-control" formControlName="fullName">
        </div>
        <div *ngIf="f['fullName'].touched && f['fullName'].invalid" class="invaild-div">
            <span class="text-left text-danger pb-1" *ngIf="f['fullName'].errors?.['required']">
                Name is required
            </span>
            <span class="text-left text-danger pb-1" *ngIf="f['fullName'].errors?.['pattern']">
                Please enter a valid name.
            </span>
        </div>
        <!-- Input Field For Email -->
        <div class="input-container">
            <span class="icon"><img src="../../../../assets/images/email-icon.svg" /></span>
            <input type="email" placeholder="Email" class="form-control" formControlName="email">
        </div>
        <div *ngIf="f['email'].touched && f['email'].invalid" class="invaild-div">
            <span class="text-left text-danger pb-1" *ngIf="f['email'].errors?.['required']">Email is required.
            </span>
            <span class="text-left text-danger pb-1" *ngIf="f['email'].errors?.['pattern']">
                Please enter a valid email.
            </span>
        </div>
        <!-- Input Field For Phone Number -->
        <div class="input-container">
            <span class="icon"><img src="../../../../assets/images/phone-icon.svg" /></span>
            <input type="text" placeholder="Phone Number" class="form-control" formControlName="phoneNumber">
        </div>
        <div
        *ngIf="f['phoneNumber'].touched && f['phoneNumber'].invalid"
        class="invaild-div"
      >
        <span
          class="text-left text-danger pb-1"
          *ngIf="f['phoneNumber'].errors?.['required']"
          >Phone Number is required.
        </span>
        <span
          class="text-left text-danger pb-1"
          *ngIf="f['phoneNumber'].errors?.['pattern']"
        >
          Please enter a valid Phone Number.
        </span>
      </div>
      <!-- Conitnue Button -->
      <button class="btn-continue" (click)="toContinue()">
        Continue
     </button>
    </form>
</div>