<div *ngIf="!show">
  <div class="close-icon" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h4>Apply on Worknigeria or log in</h4>
  <hr />
  <p>
    Already a member of Worknigeria?
    <a
      (click)="onNoClick()"
      routerLink="/login"
      style="color: #3eab70; text-decoration: none; font-weight: 700"
      >Sign in first</a
    >
  </p>
  <br />
  <!-- <button mat-raised-button class="file-upload-btn"><i>+</i> UPLOAD ATTACHMENTS</button> -->
  <div class="upload-pdf">
    <div class="custom-file-upload">
      <button mat-raised-button class="file-upload-btn">
        <i>+</i> UPLOAD CV
      </button>
      <input
        type="file"
        accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="onSelectFile($event)"
        name="file"
      />
    </div>
    <p style="color: #3eab70">{{ fileName }}</p>
  </div>
  <br />
  <div class="upload-pdf">
    <div class="custom-file-upload">
      <button mat-raised-button class="file-upload-btn">
        <i>+</i> UPLOAD COVER LETTER
      </button>
      <input
        type="file"
        accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="onSelectFileCoverLetter($event)"
        name="file"
      />
    </div>
    <p style="color: #3eab70">{{ fileNameCoverLetter }}</p>
  </div>
  <p class="text-right cusgap-mobile">
    <button mat-flat-button (click)="onNoClick()" style="color: #3eab70">
      CANCEL</button
    >&nbsp;
    <button mat-raised-button color="primary" (click)="applyJob()">
      APPLY NOW
    </button>
  </p>
</div>

<div *ngIf="show">
  <div class="close-icon" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h4>Apply on Worknigeria</h4>
  <hr />
  <!-- <form method="post" enctype="multipart/form-data"> -->
  <div class="row align-items-center">
    <div class="col-lg-12">
      <P>Select a CV from your profile or upload attachments.</P>
    </div>
    <div class="col-lg-6">
      <div class="custom-file-upload">
        <button mat-raised-button class="file-upload-btn w-100">
          <i>+</i> UPLOAD CV
        </button>
        <input
          *ngIf="!applyButton"
          type="file"
          accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          (change)="onSelectFile($event)"
          name="file"
        />
      </div>
      <!-- <p style="color: #3EAB70;" *ngIf="!applyButton">{{fileName}}</p> -->
    </div>
    <div class="col-lg-6">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>CV</mat-label>
        <mat-select
          (selectionChange)="selectByUploadDocs($event)"
          [(ngModel)]="selectedCV"
        >
          <mat-option
            *ngFor="let row of uploadDocsRecords; let i = index"
            [value]="row?.id"
          >
            {{ row?.documentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <div class="custom-file-upload">
        <button mat-raised-button class="file-upload-btn w-100">
          <i>+</i> UPLOAD COVER LETTER
        </button>
        <input
          type="file"
          *ngIf="!applyButtonCover"
          accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          (change)="onSelectFileCoverLetter($event)"
          name="file"
        />
      </div>
      <!-- <p style="color: #3EAB70;" *ngIf="!applyButtonCover">{{fileNameCoverLetter}}</p> -->
    </div>
    <div class="col-lg-6">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>COVER LETTER</mat-label>
        <mat-select
          (selectionChange)="selectByUploadDocsCoverLetter($event)"
          [(ngModel)]="selectedCVcover"
        >
          <mat-option
            *ngFor="let row of uploadDocsRecordsCoverLetter; let i = index"
            [value]="row?.id"
          >
            {{ row?.documentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6" *ngIf="this.userDeatils.isResumeParsed">
      <div class="form-group">
        <mat-checkbox
          class="example-margin"
          [checked]="checked"
          [disabled]="disabled"
          (change)="checkDeclaration($event.checked)"
        >
          Include link to my Worknigeria profile
        </mat-checkbox>
      </div>
    </div>
    <div class="col-lg-12">
      <p class="text-right">
        <button mat-flat-button (click)="onNoClick()" style="color: #3eab70">
          CANCEL</button
        >&nbsp;
        <button mat-raised-button color="primary" (click)="applyJob()">
          APPLY NOW
        </button>
      </p>
    </div>
  </div>

  <!-- <P>Select a COVER LETTER from your profile or upload attachments.</P> -->

  <!-- 
        <div class="col-lg-12 mb-2">
            <div class="form-group">
                
                <mat-checkbox class="example-margin" [checked]="checked" [disabled]="disabled"
                    (change)="checkDeclaration($event.checked)">
                    include link to my WorkNigeria profile
                </mat-checkbox>
            </div>
        </div>
        -->

  <!-- </form> -->
</div>
