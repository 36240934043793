<footer class="main-footer">
  <div class="footer-top">
    <div class="container-fluid full-gap">
      <div class="row">
        <!-- <div class="flex"> -->
          <div class="col-md-3">
            <div class="logo">
              <a href="javascript:void(0);" routerLink="/home"
                ><img class="footer-company-logo" src="../assets/images/footer-company-logo-white.webp"
              /></a>
            </div>
            <p class="back-to-work-text">Putting Nigeria Back To Work</p>
            <br>
            <h6 class="footer-address-heading">
              Address
            </h6>
            <p class="footer-address-text">
              11B Rev. Ogunbiyi Street, Ikeja GRA Ikeja Lagos.
            </p>
            <div class="social-media2">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/people/Worknigeria/61553936816024/"
                    target="_blank"
                    ><img src="../../../../assets/images/facebook.png"
                  /></a>
                </li>
                <li>
                  <a href="https://twitter.com/worknigerialtd" target="_blank"
                    ><img src="../../../../assets/images/twitter.png"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/worknigeria/"
                    target="_blank"
                    ><img src="../../../../assets/images/linkedin-footer.png"
                  /></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/worknigeria/"
                    target="_blank"
                    ><img src="../../../../assets/images/instagram.png"
                  /></a>
                </li>
              </ul>
            </div>
            <p class="copyright-text">Worknigeria ©2025</p>
          </div>
          <div class="col-md-1 col-sm-4">
            <h5>Employer</h5>
            <div class="nftr">
              <ul>
                <li>
                  <a href="javascript:void(0);" (click)="postAjob()"
                    >Post a Job</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="Dashboard()"
                    >Get Verified</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="cvSearch()"
                    >Search CVs</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="goToPlans()"
                    >Plans</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-1 col-sm-4">
            <h5>Job Seeker</h5>
            <div class="nftr">
              <ul>
                <li>
                  <a href="javascript:void(0);" routerLink="/job-search"
                    >Search Jobs</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="buildCVsection()"
                    >Build CV</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" (click)="job_alert()"
                    >Job Alerts</a
                  >
                </li>
                <li>
                  <a href="/upload-cvs"
                    >Upload CV</a
                  >
                </li>
              </ul>
            </div>
          </div>
          
          <div class="col-md-1 col-sm-4">
            <h5>Work Nigeria</h5>
            <div class="nftr">
              <ul>
                <li>
                  <a href="javascript:void(0);" routerLink="/aboutUs"
                    >About us</a
                  >
                </li>
                <li><a href="javascript:void(0);">Blog</a></li>
                <li> <a href="javascript:void(0);" routerLink="/services">Services</a></li>
                <li>
                  <a href="javascript:void(0);" routerLink="/contactUs"
                    >Contact us</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" routerLink="/terms-and-conditions"
                    >Terms</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" routerLink="/privacy-policy"
                    >Privacy Policy</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col subscribe">
            <h5>Subscribe</h5>
            <p>Don’t miss out on our latest news and blog posts</p>
            <form [formGroup]="subscribtionForm">
              <input
                type="email"
                placeholder="Your email address"
                class="form-control input-email"
                formControlName="email"
              />
              <button
                type="submit"
                (click)="subscribtion()"
                class="nbtn"
                value="submit"
              >
                Subscribe
              </button>
              <div
                *ngIf="f.email.touched && f.email.invalid"
                class="invaild-div"
              >
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="f.email.errors.required"
                  >Email is required
                </span>
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="f.email.errors?.pattern"
                >
                  Enter valid email .
                </span>
              </div>
            </form>
          </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</footer>