<div class="tab-content py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active" id="nav-about">
    <div class="title-billing">
      <h1>
        Choose the plan that best<br />
        fits your hiring needs
      </h1>
    </div>

    <section class="container-fluid billing-area">
      <div class="row">
        <div class="col-lg-4">
          <div class="billing-box core">
            <div class="billing-box-label">10% <span>OFF</span></div>
            <div class="billing-box-desc">
              <h3>CORE</h3>
              <p>
                <strong
                  >An affordable solution for small companies looking to
                  grow.</strong
                >
              </p>
              
              <ul>
                <li>Unlimited job posts</li>
                <li>Featured job posts</li>
                <li>Search all CVs on Worknigeria</li>
                <li>Manage & communicate with applicants</li>
                <li>Full reporting suite</li>
              </ul>
              <h4>₦40,000/MONTH</h4>
            </div>
            <div class="billing-box-btn">
              <a href="#" class="btn-activate">Activate</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="billing-box growth">
            <div class="billing-box-label">10% <span>OFF</span></div>
            <div class="billing-box-desc">
              <h3>GROWTH</h3>
              <p>
                <strong
                  >A comprehensive platform for companies looking to hire at
                  scale.</strong
                >
              </p>
              <p>
                <strong>Everything in Core, plus:</strong><br />
                Branded careers page on Worknigeria<br />
                Whitelabel career portal on your site<br />
                Dedicated account manager
              </p>
              <h4>₦ 60,000/MONTH</h4>
            </div>
            <div class="billing-box-btn">
              <a href="#" class="btn-activate">Activate</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="billing-box premier">
            <div class="billing-box-label">10% <span>OFF</span></div>
            <div class="billing-box-desc">
              <h3>PREMIER</h3>
              <p>
                <strong
                  >An expansive solution for businesses looking for top-tier
                  service.</strong
                >
              </p>
              <p>
                <strong>Everything in Growth, plus:</strong><br />
                Priority support<br />
                Single sign-on<br />
                Anonymized screening
              </p>
              <h4>₦ 90,000/MONTH</h4>
            </div>
            <div class="billing-box-btn">
              <a href="#" class="btn-activate">Activate</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
