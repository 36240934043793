<div
  property="schema:text"
  class="trmsncnd clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item common-padd"
>
  <div class="container">
    <h2>Privacy Policy</h2>
    <h4>Overview</h4>

    <p>
      Worknigeria, LTD and its subsidiaries and divisions (collectively,
      "Worknigeria", "we" or "our") provides this Privacy Policy to
      explain our data and security practices and policies on Worknigeria
      websites, Worknigeria websites and software applications (collectively,
      the "Sites"). This Privacy Policy describes the types of Personal
      Information we collect, the sources from which we collect it, how we use
      the information, with whom we share it, and the choices you can make about
      our collection, use and disclosure of your Personal Information. The
      phrase "Personal Information" refers to information that personally
      identifies you, either directly or in combination with other information,
      such as your name, address, telephone number, email address, credit card
      number or other billing information. We also describe the measures we take
      to protect the security of your Personal Information and how you can
      contact us about our privacy practices. This Privacy Policy incorporates
      by reference the Terms and Conditions for the Sites, which apply to this
      Privacy Policy. When you visit the Sites, provide us with information or
      publicly post information, you consent to our use and disclosure of the
      information we collect or receive as described in this Privacy Policy. We
      provide you choices that allow you to opt-out or control how we use and
      share your Personal Information
    </p>

    <p>
      Please review this Privacy Policy periodically as we may update it from
      time to time to reflect changes in our data practices. We will post a
      prominent notice on our Sites to notify you of any significant changes to
      our Privacy Policy and indicate the new effective date at the top of the
      notice. If we make material changes to the policy that will adversely
      affect previously collected Personal Information, you will be notified and
      provided an opportunity to consent to the different practices specified in
      the updated Privacy Policy. If you do not consent, you may delete your
      account and stop visiting or using the Sites. Your continued use of the
      Sites after we publish or send a notice about our changes to this Privacy
      Policy means that you are consenting to the updated Privacy Policy.<br />
      &nbsp;
    </p>

    <h4>Information We Collect</h4>

    <p>
      Worknigeria collects Personal Information in a fair and lawful way,
      for the purposes specified in this Privacy Policy. The types of
      information we generally may obtain can include:
    </p>

    <ul>
      <li>
        Personal Information provided by you (such as via a resume uploaded to
        our Site or a job application sent using our Site) or collected from
        third party sources as described below.
      </li>
      <li>
        Publically available data about you from third party sites and sources,
        including profiles created by you on other websites, or other
        information you have publicly shared for the purpose of advertising or
        informing others about your background.
      </li>
      <li>
        Username and password for the account you may establish on our Sites.
      </li>
      <li>
        Payment details (including payment card number, security code,
        expiration date, cardholder name and billing address) if you buy
        products on our Sites.
      </li>
      <li>
        Your demographic information (such as zip or postal code, occupation,
        education and experience, and if you choose to provide it, age, gender
        and race or ethnicity). We collect this information either through the
        registration process, from your resume or in the manner described below.
      </li>
      <li>
        Job search behavior and preferences, and a record of the searches that
        you make on our Sites.
      </li>
      <li>
        Other details that you may submit to us or that may be included in the
        information provided to us by third parties.
      </li>
      <li>
        Technical information about your use of our sites or apps, as described
        below.<br />
        &nbsp;
      </li>
    </ul>
    <p>
      We have obtained and may continue to obtain information about you from
      various sources, including directly from you via our Sites, when you call
      or email us or communicate with us through social media, or when you
      participate in events, contests or other promotions. We also may obtain
      information about you from our parent, affiliate or subsidiary companies,
      business partners and other third parties, as well as from publicly
      available information.
    </p>

    <p>
      If you decide to download one of our Apps, and if you authorize us to do
      so, we will collect your social media profile information, including your
      social network site user ID, work email address, name, city, state, social
      network site profile image URL, and employment history, including job
      titles and company names. You may be asked to enter this information
      directly, or we can automatically collect it by syncing to your social
      media profiles. We request this information so that we may automate
      completion of registration forms or job applications with information
      stored in your social media profile. In addition, when you visit our Sites
      or use our Apps, we may collect certain information by automated means,
      such as cookies and web beacons or as provided to the app by your device's
      operating system, as described in more detail below. The information we
      may collect automatically via the Sites can include:
    </p>

    <ul>
      <li>
        Information about the devices our visitors use to access the Internet
        (such as the IP address and the device, browser and operating system
        type and other operating system support information).
      </li>
      <li>
        Pages and URLs that refer visitors to our Sites, also pages and URLs
        that visitors exit to once they leave our Sites.
      </li>
      <li>Dates and times of visits to our Sites.</li>
      <li>
        Information on actions taken on our Sites (such as page views, site
        navigation patterns and job view or application activity).
      </li>
      <li>
        A general geographic location (such as country and city) from which a
        visitor accesses our Sites.
      </li>
      <li>
        Search terms that visitors use to reach our Sites.<br />
        &nbsp;
      </li>
    </ul>
    <p>
      Note that your Personal Information may also be automatically collected,
      and used, as described in the "Cookies, Tracking Choices, and Third Party
      Advertisers"
    </p>

    <h4>How We Use the Information We Collect</h4>

    <p>We may use the information we obtain about you to:</p>

    <ul>
      <li>Register, manage and maintain your account on the Sites.</li>
      <li>Provide products or services you request.</li>
      <li>
        Maintain your resume and/or Profile and make it available to recruiters
        through our products and services
      </li>
      <li>Supplement your Profile with publicly available information.</li>
      <li>
        Process, validate and deliver your purchases (including by processing
        payment card transactions and contacting you about your orders,
        including by telephone).
      </li>
      <li>Maintain a record of the jobs you view or apply to on our Sites.</li>
      <li>
        Inform you of relevant job postings that may be of interest to you based
        on your interests as expressed previously through your job search
        behavior and preferences.
      </li>
      <li>
        Provide administrative notices or communications applicable to your use
        of the Sites.
      </li>
      <li>
        Respond to your questions and comments and provide customer support.
      </li>
      <li>
        Contact you and deliver information to you that, in some cases, is
        targeted to your interests (such as relevant services, educational or
        other career development opportunities); enable you to communicate with
        us through our blogs, social networks and other interactive media; and
        solicit your feedback and input. These communications will contain links
        for preference management and, where appropriate, unsubscribe links
        should you decide you do not want to receive further communications.
      </li>
      <li>
        Manage your participation in our events and other promotions, where you
        have signed up for such events and promotions.
      </li>
      <li>
        Operate, evaluate and improve our business and the products and services
        we offer.
      </li>
      <li>
        Analyze and enhance our marketing communications and strategies
        (including by identifying when emails we have sent to you have been
        received and read).
      </li>
      <li>
        Analyze trends and statistics regarding visitors' use of our Sites,
        mobile applications and social media assets, and the jobs viewed or
        applied to on our Sites.
      </li>
      <li>
        Analyze trends and statistics about the job market and career mobility
        in local economies and nationally, and provide these analytics to
        customers of certain Worknigeria products and services.
      </li>
      <li>
        Optimize our site search engine results and permit search engine access
        to public Profile information.
      </li>
      <li>
        Protect against and prevent fraud, unauthorized transactions, claims and
        other liabilities, and manage risk exposure, including by identifying
        potential hackers and other unauthorized users.
      </li>
      <li>
        Enforce our Sites'&nbsp;<strong
          style="cursor: pointer"
          routerLink="/terms-and-conditions"
          >Terms and Conditions</strong
        >.
      </li>
      <li>
        Comply with applicable legal requirements, court orders, legal
        proceedings, document requests, and industry standards and our
        policies.<br />
        &nbsp;
      </li>
    </ul>
    <p>
      We also use certain technical information about your computer or other
      device and your access of the Sites (including your internet protocol
      address) in order to operate, maintain and manage the Sites. We collect
      this information by automated means, such as cookies and web beacons.
    </p>

    <p>
      Worknigeria only uses your Personal Information that is necessary
      for the purposes described in this Privacy Policy, or as permitted by law.
      If we seek to use the information we obtain about you in other ways, we
      will provide specific notice and request your consent at the time of
      collection.
    </p>

    <h4>Information We Share</h4>

    <p>
      We provide a platform that allows other members and third parties to see
      user profiles and give access to those profiles or create other profiles
      based on platform profiles and other information from third parties or
      publicly available sources that we may in turn sell to third parties as
      part of our products and services. This section of our policy describes
      the various ways in which we may share your personal information. You can
      learn more about your privacy choices, including your right to opt-out of
      the sale of your personal information.
    </p>

    <p>
      We share the information that we collect from you or from third party
      sources with our affiliated companies, recruiters, educators and service
      providers. In the normal course of performing services for our clients,
      Personal Information may be shared within Worknigeria and its
      affiliates for the purposes specified in this Privacy Policy; as well as
      research and statistical purposes, system administration and crime
      prevention or detection.
    </p>

    <p>
      Recruiters may use your information to contact you directly. Additionally,
      when you apply for a job with a company through the Sites, the information
      supplied by you or shared through Worknigeria products and services
      may become part of such company's database. Similarly, if your Worknigeria resume or your Profile is downloaded by a recruiter, your
      information may become a part of their database. In these instances, the
      use of such information by the recruiter will be subject to the privacy
      policy of that company, and we are not responsible for that company's use
      of your information.
    </p>

    <p>
      Where you have provided Personal Information on a Site that is "hosted" or
      "co-branded" by another company then that information will be made
      available to both Worknigeria and the hosting/co-branding company.
      Worknigeria is not responsible for what the hosting/co-branding
      company, or any third party with whom you may share your Personal
      Information, subsequently does with your information. You should review
      the privacy policy of the hosting/co-branding company to ensure that you
      are comfortable with how it intends to use and disclose your information.
    </p>

    <p>
      All users should be aware that when they choose to display or distribute
      Personal Information (such as their email address or other contact
      information on a public resume) via the Sites or any other distribution
      method, that information can be collected and used by others. This may
      result in unsolicited messages from third parties. We also may share
      Personal Information with our service providers who help us in the
      delivery of our own products and services to you. These service providers
      are required by contract or law to only use or disclose the information as
      necessary to perform services on our behalf or as otherwise required by
      law. Where we have knowledge that a service provider or partner is using
      or disclosing Personal Information in a manner inconsistent with this
      Policy, Worknigeria will take reasonable steps to prevent or stop
      such improper use and/or disclosure.
    </p>

    <p>
      Worknigeria preserves the right to disclose without your prior
      permission any Personal Information about you or your use of this Site if
      we have a good faith belief that such action is necessary to: (a) protect
      and defend the rights, property or safety of Worknigeria, employees,
      other users of the Sites, or the public; (b) enforce the terms and
      conditions that apply to use of the Sites; (c) as required by a legally
      valid request from a competent governmental authority; or (d) respond to
      claims that any content violates the rights of third-parties. We may also
      disclose Personal Information as we deem necessary to satisfy any
      applicable law, regulation, legal process or governmental request.
    </p>

    <p>
      In addition, Personal Information we have collected may be passed on to a
      third party in the event of a transfer of ownership or assets or a
      bankruptcy or other corporate reorganization of Worknigeria.&nbsp;
    </p>

    <h4>Analytics</h4>

    <p>
      We may combine certain non-personally identifiable Aggregate Data, or
      otherwise anonymized or de-identified data about our users, and use such
      data to prepare reports for our users (typically recruiters or educators).
      "Aggregate Data" for our purposes is demographic information (such as zip
      code, age, gender, race or ethnicity) and employment information (such as
      occupation, education and experience) of many individuals that is combined
      together. Aggregate Data is used to analyze the characteristics of various
      populations and does not identify any specific individuals. We may also
      use and share anonymized or de-identified data to track trends in the
      labor market as well as use of our products and services. Some Aggregate
      Data analytics reports can be used to better understand individual
      Profiles. For example, our Recruitment Edge product provides aggregate
      data trends on the longevity of specific career positions to allow
      recruiters to identify Profiles of individuals who may be more likely to
      be interested in making a career move.
    </p>

    <h4>Review, Update or Delete Your Account Information or Profile&nbsp;</h4>

    <p>
      Worknigeria takes reasonable steps to ensure that Personal
      Information is reliable for its intended use; as well as being current,
      accurate, and complete. However, information is collected from a variety
      of sources, and cannot be guaranteed to be current, accurate or complete,
      and in particular, we are not responsible for the accuracy or completeness
      of information whose source Worknigeria does not control. As part of
      our efforts to maintain Personal Information, which is reasonably
      accurate, current, and complete; we need your help. You can update your
      profile directly on our Site.
    </p>

    <p>
      You may access, update and amend Personal Information included in your
      online Worknigeria account or remove your resume from our resume
      database at any time by logging into your account and making the necessary
      changes. You may also delete your account, including your Personal
      Information and any resumes, from our resume database at any time by
      logging in to your account and
    </p>

    <h4>Links to Other Websites</h4>

    <p>
      Our Sites may contain links to other sites for your convenience and
      information. These sites may be operated by companies not owned by
      Worknigeria. These other sites or linked third-party sites may have
      their own privacy notices, which you should review if you visit those
      sites. We are not responsible for the content of any sites not owned by
      Worknigeria, any use of those sites, or those sites' privacy
      practices.
    </p>
  </div>
</div>
