<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<div class="mobile-first-profile-option-modal">
  <h4 style="text-align: left"><b>Create your profile</b></h4>
  <br />
  <p style="text-align: left">
    A complete Worknigeria profile will make it easier to apply for jobs, and
    allow employers to find you,
  </p>
  <br />
  <h6 style="text-align: left">
    <strong style="color: #000">Import your CV</strong>
  </h6>
  <p style="text-align: left">
    Already have a CV? Upload the file (doc, docx, or pdf) and we will
    automatically convert it.
  </p>
  <p style="text-align: left">
    <span class="upload-cv-btn-wrap">
      <a href="javascript:void(0);" class="btn-green">Upload CV</a>
      <input
        type="file"
        (change)="onSelectFile($event)"
        accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
    </span>
  </p>

  <P class="custom-divider"><span>or</span></P>

  <h6 style="text-align: left">
    <strong style="color: #000">Enter information manually</strong>
  </h6>
  <p style="text-align: left">
    Type in your work experience, education and skills.
  </p>
  <br />
  <div class="wrap">
    <p style="text-align: left">
      <span
        ><a href="javascript:void(0);" class="btn-green" (click)="onNoClick()">
          Get started</a
        >
      </span>
    </p>
    <p style="float: right">
      <a href="javascript:void(0);" style="color: green" (click)="onNoClick()">
        Skip for now</a
      >
    </p>
  </div>
</div>
