<div class="sign-lt">
  <div class="form-wrap">
    <form [formGroup]="employerSignup" autocomplete="off">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            placeholder="Email"
            required="required"
            formControlName="email"
            [pattern]="emailPattern"
          />
        </mat-form-field>
        <div *ngIf="f.email.touched && f.email.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.email.errors.required"
            >Email is required
          </span>
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.email.errors?.pattern"
          >
            Email is not valid.
          </span>
        </div>
      </div>

      <div class="form-group">
        <input
          type="submit"
          value="Continue"
          class="btn btn-green w-100"
          (click)="completeRegistration()"
        />
      </div>
    </form>
  </div>
  <div class="or-section">
    <div class="or">or register by</div>
  </div>
  <div class="login-options">
    <ul>
      <li>
        <button (click)="signInWithFB()">
          <img src="../../../../assets/images/face1.png" />
        </button>
      </li>
      <li>
        <button (click)="signInWithgoogle()">
          <img src="../../../../assets/images/goo1.png" />
        </button>
      </li>
      <li>
        <button (click)="loginWithLinkedin()">
          <img src="../../../../assets/images/lin1.png" />
        </button>
      </li>
    </ul>
  </div>

  <div class="btm-swipe-link">
    <p>
      Already on Worknigeria?
      <a href="javascript:void(0);" (click)="login()">Log in</a>
    </p>
  </div>
</div>
