<div class="sign-up-background">
    <div class="row to-sign-in">
        <p>Already have an account? &nbsp;<a href="javascript:void(0);" (click)="signIn()"> Sign in!</a> </p>
    </div>
    <div class="row auth-main-signup">
        <div class="col-lg-5 col-12 img-div col-12">
            <img [src]= "isSmallScreen ? '../../../../assets/images/employer-signup-mobile.webp' : '../../../../assets/images/employer-signup.webp'" class="employer-img" alt="Employer Sign up" />
        </div>
        <div class="col-lg-7 col-12">
            <div class="employer-text">
                <h2>Sign up as an Employer</h2>
                <p>It only takes a few seconds</p>
            </div>
            <!-- <div class="signup-options">
                    <button class="btn-signup" (click)="signInWithgoogle()">
                        <img src="../../../../assets/images/goo1.png" /> Google
                    </button>
                    <button class="btn-signup ml-3" (click)="signInWithFB()">
                        <img src="../../../../assets/images/fb-circle.png" /> Facebook
                    </button>
            </div>
            <div class="or-continue">
                <div class="or">Or continue with</div>
            </div> -->
            <div class="form-margin form-wrap d-flex justify-content-center align-items-center">
                <form [formGroup]="employerSignup" autocomplete="off" class="main-ctr">
                    <!-- first and last name -->
                    <div class="row">
                        <div class="col-lg-6 col-12 form-group">
                            <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                                <mat-label>First Name</mat-label>
                                <input type="text" matInput placeholder="First Name" required="required"
                                    formControlName="firstName" />
                            </mat-form-field>
                            <div class="error-msg" *ngIf="f.firstName.touched && f.firstName.invalid">
                                <span class="text-danger pb-1" *ngIf="f.firstName.errors.required">First Name is
                                    required</span>
                                <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors?.pattern">
                                    Please enter a valid name.
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12 form-group">
                            <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                                <mat-label>Last Name</mat-label>
                                <input type="text" matInput placeholder="Last Name" required="required"
                                    formControlName="lastName" />
                            </mat-form-field>
                            <div class="error-msg" *ngIf="f.lastName.touched && f.lastName.invalid">
                                <span class="text-danger pb-1" *ngIf="f.lastName.errors?.required">
                                    Last Name is required
                                </span>
                                <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors?.pattern">
                                    Please enter a valid name.
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- company name -->
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                            <mat-label>Company Name</mat-label>
                            <input type="text" matInput placeholder="Company Name" required="required"
                                formControlName="companyName" />
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.companyName.touched && f.companyName.invalid">
                            <span class="text-danger pb-1" *ngIf="f.companyName.errors?.required">
                                Company name is required
                            </span>
                            <span class="text-left text-danger pb-1" *ngIf="f.companyName.errors?.pattern">
                                Please add a Company Name.
                            </span>
                        </div>
                    </div>
                    <!-- Email -->
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                            <mat-label>Enter Email</mat-label>
                            <input type="email" matInput placeholder="Enter Email" required="required"
                                formControlName="email" [pattern]="emailPattern" />
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.email.touched && f.email.invalid">
                            <span class="text-danger pb-1" *ngIf="f.email.errors?.required">Email is required</span>
                            <span class="text-danger pb-1" *ngIf="f.email.errors?.pattern">Email is not
                                valid.</span>
                        </div>
                    </div>
                    <!-- Phone Number -->
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                            <mat-label>Phone Number</mat-label>
                            <input type="tel" matInput placeholder="+2348012345678" required="required"
                                formControlName="phoneNumber" [pattern]="phonePattern" maxlength="15"/>
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid">
                            <span class="text-danger pb-1" *ngIf="f.phoneNumber.errors?.required">Phone Number is required</span>
                            <span class="text-danger pb-1" *ngIf="f.phoneNumber.errors?.pattern">Phone Number is not
                                valid.</span>
                        </div>
                    </div>
                    <!-- Password -->
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                            <mat-label>Password</mat-label>
                            <input type="password" matInput placeholder="Password" required="required"
                                formControlName="password" [pattern]="passwordPattern"
                                [type]="showPassword ? 'text' : 'password'" />
                            <mat-icon matSuffix (click)="togglePasswordVisibility()" style="cursor: pointer">{{
                                showPassword ? "visibility" : "visibility_off"
                                }}</mat-icon>
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.password.touched && f.password.invalid">
                            <span class="text-danger pb-1" *ngIf="f.password.errors.required">
                                Password is required
                            </span>
                            <span class="text-danger pb-1" *ngIf="f.password.errors?.pattern">
                                You must use at least one upper case letter, one lower case letter, one number, and one
                                special character. The password should be at least 8 characters long
                            </span>
                        </div>
                    </div>
                    <!-- Confirm Password -->
                    <div class="form-group">
                        <!-- Confirm Password Input Field -->
                        <mat-form-field class="example-full-width" appearance="fill" hideRequiredMarker>
                            <mat-label>Confirm Password</mat-label>
                            <input type="password" matInput placeholder="Confirm Password" required="required"
                                formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'" />
                            <mat-icon matSuffix (click)="toggleConfirmPasswordVisibility()" style="cursor: pointer">{{
                                showConfirmPassword ? "visibility" : "visibility_off" }}
                            </mat-icon>
                        </mat-form-field>
                        <div class="error-msg" *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid">
                            <!-- Confirm Password Validation Messages -->
                            <span class="text-danger" *ngIf="f.confirmPassword.errors?.required">Confirm Password is
                                required</span>
                            <span class="text-danger" *ngIf="f.confirmPassword.errors?.passwordMismatch">Passwords
                                do not match</span>
                        </div>
                    </div>
                    <!-- Create Account Button -->
                    <div class="form-group">
                        <button class="btn-create-account" (click)="createAccount()">Create Account</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row terms-of-use">
        <p>By continuing you indicate that you read and agreed to the <a href="javascript:void(0);"
                (click)="termsOfUse()">&nbsp;Terms of Use</a></p>
    </div>
</div>