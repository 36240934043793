<div *ngIf="show">
  <div class="close-icon m20" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h4>Apply at company site or log in</h4>
  <hr />
  <p>
    Clicking to continue will take you to the company’s website to complete your
    application. Your activity on WorkNigeria will be saved. Please note that we
    are not responsible for the content of any external websites.
  </p>
  <br />
  <p class="text-right">
    <button mat-raised-button color="primary" (click)="applyJob()">
      Continue
    </button>
  </p>
</div>

<div *ngIf="!show">
  <div class="close-icon m20" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h4>Apply at company site</h4>
  <hr />
  <p>
    Clicking to continue will take you to the company’s website to complete your
    application. Your activity on Worknigeria will be saved. Please note that we
    are not responsible for the content of any external websites.
  </p>
  <br />
  <p class="text-right">
    <button mat-raised-button color="primary" (click)="applyJob()">
      Continue
    </button>
  </p>
</div>
